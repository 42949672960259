import React from 'react'
import Layout from '../components/layout'
import KeyVisual from '../components/modules/visuals/TheKeyVisual'
import SEO from '../components/seo'
import image from '../images/offers.jpg'

const IndexPage = () => (
  <Layout>
    <SEO title="Offers" />
    <KeyVisual title="Offers" image={image} />
  </Layout>
)

export default IndexPage
